import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 d-flex justify-space-between flex-wrap"},[_c('div',{staticClass:"inputCont"},[_c(VTextField,{attrs:{"label":"Risk Reference","disabled":""},model:{value:(_vm.subscription_id),callback:function ($$v) {_vm.subscription_id=$$v},expression:"subscription_id"}})],1),_c('div',{staticClass:"inputCont"},[_c(VSelect,{attrs:{"label":"Status","items":_vm.statusEng,"item-text":"data","item-value":"id","disabled":_vm.statusEng.length === 0},on:{"blur":function($event){_vm.SET_BOUND_ENG('status', this);
        _vm.checkField('status');}},model:{value:(_vm.$v.boundEng.status.$model),callback:function ($$v) {_vm.$set(_vm.$v.boundEng.status, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.boundEng.status.$model"}})],1),_c('div',{staticClass:"inputCont"},[_c(VSelect,{attrs:{"label":"Line","items":_vm.risk_type,"item-text":"key","item-value":"id","hint":("" + (_vm.accountInformation.typeOfRisk.description || 'Select a Line of Risk')),"disabled":""},model:{value:(_vm.accountInformation.typeOfRisk),callback:function ($$v) {_vm.$set(_vm.accountInformation, "typeOfRisk", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountInformation.typeOfRisk"}})],1),_c('div',{staticClass:"inputCont"},[_c(VTextField,{attrs:{"label":"Business","disabled":""},model:{value:(_vm.subscription_type),callback:function ($$v) {_vm.subscription_type=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"subscription_type"}})],1),_c('div',{staticClass:"inputCont"},[_c(VSelect,{attrs:{"label":"Underwriter","items":_vm.underwriter,"item-text":"data","item-value":"id","disabled":_vm.underwriter.length === 0},on:{"blur":function($event){_vm.SET_BOUND_ENG('underwriter', this);
        _vm.checkField('underwriter');}},model:{value:(_vm.$v.boundEng.underwriter.$model),callback:function ($$v) {_vm.$set(_vm.$v.boundEng.underwriter, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.boundEng.underwriter.$model"}})],1),_c('div',{staticClass:"inputCont"},[_c(VSelect,{attrs:{"label":"UW Analist 1","items":_vm.underwriter,"item-text":"data","item-value":"id","disabled":_vm.underwriter.length === 0},on:{"blur":function($event){_vm.SET_BOUND_ENG('awAnalist1', this);
        _vm.checkField('awAnalist1');}},model:{value:(_vm.$v.boundEng.awAnalist1.$model),callback:function ($$v) {_vm.$set(_vm.$v.boundEng.awAnalist1, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.boundEng.awAnalist1.$model"}})],1),_c('div',{staticClass:"inputCont"},[_c(VSelect,{attrs:{"label":"UW Analist 2","items":_vm.underwriter,"item-text":"data","item-value":"id","disabled":_vm.underwriter.length === 0},on:{"blur":function($event){_vm.SET_BOUND_ENG('awAnalist2', this);
        _vm.checkField('awAnalist2');}},model:{value:(_vm.$v.boundEng.awAnalist2.$model),callback:function ($$v) {_vm.$set(_vm.$v.boundEng.awAnalist2, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.boundEng.awAnalist2.$model"}})],1),_c('div',{staticClass:"inputCont"},[_c(VSelect,{attrs:{"label":"Underwriting Month","items":_vm.underwriterMonth,"item-text":"data","item-value":"id","loading":_vm.underwriterMonth.length === 0,"disabled":""},model:{value:(_vm.selectedUnderwriterMonth),callback:function ($$v) {_vm.selectedUnderwriterMonth=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectedUnderwriterMonth"}})],1),_c('div',{staticClass:"inputCont"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Inception","readonly":"","disabled":""},model:{value:(_vm.quotation.inceptionDate),callback:function ($$v) {_vm.$set(_vm.quotation, "inceptionDate", $$v)},expression:"quotation.inceptionDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c(VDatePicker,{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.quotation.inceptionDate),callback:function ($$v) {_vm.$set(_vm.quotation, "inceptionDate", $$v)},expression:"quotation.inceptionDate"}})],1)],1),_c('div',{staticClass:"inputCont"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Expiry ","readonly":"","disabled":""},model:{value:(_vm.quotation.expiryDate),callback:function ($$v) {_vm.$set(_vm.quotation, "expiryDate", $$v)},expression:"quotation.expiryDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.quotation.expiryDate),callback:function ($$v) {_vm.$set(_vm.quotation, "expiryDate", $$v)},expression:"quotation.expiryDate"}})],1)],1),_c('div',{staticClass:"inputCont"},[_c(VTextField,{attrs:{"label":"Term","disabled":""},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }